import React from 'react';
import pic from '../images/profile.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FaLinkedin} from 'react-icons/fa';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

const Header = () => {
  return (
    <header className="header">
      <img src={pic} alt="" />
      <h3>Laura Smith</h3>
      <h5>Frontend Developer</h5>
      <h6>laurasmith.website</h6>
      <div className="buttons">
        <Button
          icon=<FontAwesomeIcon icon={faEnvelope} />
          name="Email"
          classList="mail"
        />
        <Button icon=<FaLinkedin /> name="LinkedIn" classList="lkdn" />
      </div>
    </header>
  );
};

export default Header;
