import React from 'react';

const About = () => {
  return (
    <section className="about">
      <h5>About</h5>
      <p>
        I am a frontend developer with a particular interest in making things simple and automating daily tasks. I try to keep up with security and best practices, and am always looking for new things to learn.
      </p>
    </section>
  );
};

export default About;
