import React from 'react';
import {FaTwitterSquare} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaInstagramSquare} from 'react-icons/fa';
import {FaGithubSquare} from 'react-icons/fa';

const Social = () => {
  return (
    <footer className="social-icons">
      <FaTwitterSquare />
      <FaFacebookSquare />
      <FaInstagramSquare />
      <FaGithubSquare />
    </footer>
  );
};

export default Social;
