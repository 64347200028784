import './App.css';
import React from 'react';
import About from './components/About';
import Header from './components/Header';
import Interests from './components/Interests';
import Social from './components/Social';

function App() {
  return (
    <div className="App">
      <main className='main-container'>
        <section className='card'>
          <Header />
          <About />
          <Interests />
          <Social />
        </section>
      </main>
    </div>
  );
}

export default App;
