import React from 'react';

const Interests = () => {
  return (
    <section className="interests">
      <h5>Interests</h5>
      <p>
        Food expert. Music scholar. Reader. Internet fanatic. Bacon buff. Entrepreneur. Travel geek. Pop culture ninja. Coffee fanatic.
      </p>
    </section>
  );
};

export default Interests;
